"use client";

import React from "react";

// Next
import Link from "next/link";

// Components
import { Button } from "@/components/common/Button";
import useAppTranslations from "@/utils/hooks/useAppTranslations";

const NotFoundPage: React.FC = () => {
  const { translate: t } = useAppTranslations();
  return (
    <div className="flex flex-col items-center justify-center page-height text-center px-4">
      <h1 className="text-8xl font-bold text-gray-300">404</h1>
      <h2 className="text-3xl font-semibold text-gray-400 mt-4">
        {t("Page Not Found")}
      </h2>
      <p className="text-gray-500 mt-2 max-w-md">
        {t(
          "The entity you are looking for doesn't exist or you don't have permission to access it"
        )}
      </p>
      <Link href="/dashboard" className="mt-8">
        <Button variant="blue">{t("Return to Homepage")}</Button>
      </Link>
    </div>
  );
};

export default NotFoundPage;
